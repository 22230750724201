import { UnilevelMember } from "../Unilevel/UnilevelInterfaces";
import firestore from "firebase-admin";
import { BinaryPoints } from "../System/SystemInterfaces";
import { Timestamp } from "firebase-admin/firestore";

export type userside = "left" | "right";

export interface NewUserData {
  email: string;
  username: string;
  password: string;
  passwordConfirm: string;
  enroller: string;
  binarySide: userside;
}

export interface UserInfo {
  _usernamePK: string;
  _uid: string;
  username: string;
  email: string;
  nickname: string;
  pictureUrl: string;
  currentRank: string;
  enroller: string;
  binarySide: userside;
  roles: [string];
  ranksCarrerData: any; //Map<string, RankCarrerData>;
}

export interface DownlineUser {
  username: string;
  binarySide: userside;
}

export interface RankCarrerData {
  _id: string;
  progress: number | 0;
  status: "current" | "unlocked" | "next" | "locked";
  date: Date;
  priority?: number;
}

export interface UserSubscription {
  username: string;
  subscriptionId: string;
  startDate?: Date | number | FirebaseFirestore.Timestamp;
  lastPayDate?: Date | number | FirebaseFirestore.Timestamp;
  endDate?: Date | number | FirebaseFirestore.Timestamp;
  isActive?: boolean;
  daysLeft?: number;
  isFirstTime?: boolean;
}

export interface UserSubStatus {
  subscriptionId: string;
  endDate?: Date | number;
  isActive?: boolean;
  remainingDays?: number;
}

export interface NetworkData {
  basic: NetworkDataBasic;
}

export interface NetworkDataBasic {
  binaryPercentage: number;
  binarySide: userside;
  currentRank: string;
  enroller: string;
  innerLegUnlocked: boolean | undefined;
  uplines: Array<string>;
  binaryExpiration:
    | {
        left:
          | {
              endDate: Date | firestore.firestore.Timestamp;
              username: string;
            }
          | undefined;
        right:
          | {
              endDate: Date | firestore.firestore.Timestamp;
              username: string;
            }
          | undefined;
      }
    | undefined;
  pointsExpirationDays?: {
    binary: number;
    rank: number;
  };
}

export interface NetworkDataScholarship {
  periodStartDate: Date | firestore.firestore.Timestamp;
  periodEndDate: Date | firestore.firestore.Timestamp;
  //periodDurationDays: number,
  periodPoints: number;
  nextPeriods: Array<{
    startDate: Date | firestore.firestore.Timestamp;
    endDate: Date | firestore.firestore.Timestamp;
  }>;
}

export interface DirectAffiliatesInterface {
  qualifiedVolume: {
    total: number; // Qualified Volume Points, excluding excess points
    left: number;
    right: number;
  };
  points: {
    current: number; // Total points generated in the current rank
    qualifying: number; // Volume Points granted to the enroller
    //spare: number;
    left: number;
    right: number;
    lost: {
      left: number;
      right: number;
    };
  };
  directsCount: number;
  directsActivated: number;
  liteDirectsActivated: number;
  invoicedTotal: number;
  notes?: Map<string, any>;
  rankPointsHistory?: {
    dateUpdated: Date | firestore.firestore.Timestamp;
    lastUpdatedPeriod: Date | firestore.firestore.Timestamp;
  };
  gtrLifeBonusDetermined?: {
    lastPeriod: string;
    dateUpdated: Timestamp;
  };
}

export class DirectAffiliates implements DirectAffiliatesInterface {
  qualifiedVolume: {
    total: number;
    left: number;
    right: number;
  };
  points: {
    current: number;
    qualifying: number;
    left: number;
    right: number;
    lost: {
      left: number;
      right: number;
    };
  };
  notes?: Map<string, any>;
  directsCount: number;
  directsActivated: number;
  liteDirectsActivated: number;
  invoicedTotal: number;
  rankPointsHistory?: {
    dateUpdated: Date | firestore.firestore.Timestamp;
    lastUpdatedPeriod: Date | firestore.firestore.Timestamp;
  };
  gtrLifeBonusDetermined?: {
    lastPeriod: string;
    dateUpdated: Timestamp;
  };

  constructor() {
    this.qualifiedVolume = {
      total: 0,
      left: 0,
      right: 0,
    };
    this.points = {
      current: 0,
      qualifying: 0,
      left: 0,
      right: 0,
      lost: {
        left: 0,
        right: 0,
      },
    };
    this.directsCount = 0;
    this.directsActivated = 0;
    this.liteDirectsActivated = 0;
    this.invoicedTotal = 0;
  }
}
export interface BinaryTreeNetworkDataBasicDoc {
  _usernamePK: string;
  parents: Array<string>;
}
export class BinaryTreeNodeUser {
  public loadedLevels = 0;
  public username = "";
  public nickname = "";
  public enroller = "";
  public parent = "";
  public rank = "";
  public pictureUrl = "";
  public points: {
    left: { pending: number; paid: number };
    right: { pending: number; paid: number };
  } = { left: { paid: 0, pending: 0 }, right: { paid: 0, pending: 0 } };
  public childLeft?: BinaryTreeNodeUser | string | null;
  public childRight?: BinaryTreeNodeUser | string | null;
  public subscriptions?: Array<UserSubStatus> | null;

  constructor();
  constructor(username?: string, parentUsername?: string);
  constructor(username: string, parentUsername: string);
  constructor(username = "", parentUsername = "") {
    this.username = username;
    this.parent = parentUsername;
    this.childLeft = null;
    this.childRight = null;
  }
}

export class BinaryTreeNode {
  public id = undefined;
  public enroller = "";
  public parent = "";
  public binarySide: userside;
  public points: BinaryPoints = {
    left: { paid: 0, pending: 0, lost: 0 },
    right: { paid: 0, pending: 0, lost: 0 },
  };
  public hasMatchingPoints: boolean = false;
  public childLeft?: BinaryTreeNodeUser | string | null;
  public childRight?: BinaryTreeNodeUser | string | null;
  public notes?: Map<string, any>;
  //constructor();
  constructor(enroller: string, parent: string, binarySide: userside) {
    this.enroller = enroller || "";
    this.parent = parent || "";
    this.binarySide = binarySide;
    this.childLeft = null;
    this.childRight = null;
    this.points = {
      left: { paid: 0, pending: 0, lost: 0 },
      right: { paid: 0, pending: 0, lost: 0 },
    };
  }
}

export interface ClassroomInfoResponse {
  url: string;
  username: string;
  password: string;
  loginUrl: string;
}

export interface SearchUserResponse extends UnilevelMember {
  coverPictureUrl?: string;
  city?: string;
  state?: string;
  country?: string;
  contact: Contact;
  metadata?: Metadata;
}

export interface Metadata {
  creationTime: string;
  lastSignInTime: string;
  lastRefreshTime?: string | null;
}
export interface Contact {
  email: EmailData;
  mobile?: MobileData;
  telegram?: NumberData;
  whatsapp?: NumberData;
  facebook?: StringData;
  instagram?: StringData;
  tiktok?: StringData;
}

interface Visible {
  visible: boolean;
}
interface StringData extends Visible {
  data: string;
}

interface ModifiedBy {
  modifiedBy?: string;
}

interface EmailData extends StringData, ModifiedBy {}
interface NumberData extends Visible {
  data: {
    dialCode: string;
    number: string;
    userId?: number;
  };
}

interface MobileData extends NumberData {
  whatsapp: boolean;
  telegram: boolean;
}
export interface Address {
  city?: string;
  country?: string;
  neighborhood?: string;
  state?: string;
  street?: string;
  zipCode?: string;
}
