
import { defineComponent } from "vue";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isBetween from "dayjs/plugin/isBetween";
import objectSupport from "dayjs/plugin/objectSupport";
import { mapGetters } from "vuex";
import { ClassData } from "functions/src/Classes/ClassesInterfaces";

interface IClassData {
  title: string;
  active: boolean;
  requiredRank: undefined | string;
  scheduleType: string;
  classType: string;
  imageUrl: string;
  position: number;
}

dayjs.extend(weekday);
dayjs.extend(isBetween);
dayjs.extend(objectSupport);

export default defineComponent({
  name: "classCard",
  emits: ["editClass", "deleteClass"],
  data() {
    return {
      day: "",
      hour: "",
      active: false,
      link: "",
      position: 0,
      weekDays: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
      weekDaysEs: [
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ],
      data: {} as IClassData,
      classStatus: "" as string,
      initialDay: "" as string,
      initialHour: "" as string,
    };
  },
  props: {
    classData: { required: true, type: Object as () => ClassData },
    classTime: { required: true, type: String },
    // classTitle: { required: true, type: String },
    classDate: Object,
    // scheduleType: { type: String },
    multiple: { type: Boolean },
    first: { type: Boolean },
    last: { type: Boolean },
    editPermission: { type: Boolean },
    classesPerDay: { required: true, type: Array },
    classIndex: { required: true, type: Number },
  },
  computed: {
    ...mapGetters("auth", ["getUserRole"]),
    ...mapGetters("ranks", ["rankIconClass", "rankName"]),
    btnClass() {
      switch (this.classStatus) {
        case "done":
          return "text-success border";
        case "future":
          return "text-info border";
        case "locked":
          return "text-secondary border";
        case "current":
          return "text-primary border";
        case "inactive":
          return "text-secondary border";
        default:
          return "";
      }
    },
    isActive() {
      return this.active || this.classData.active;
    },
    isBasicOrVipAllowed() {
      return (
        this.classData.availableForPackage?.includes("gtr-basic") ||
        this.classData.availableForPackage?.includes("gtr-vip")
      );
    },
    isLiteAllowed() {
      return this.classData.availableForPackage?.includes("gtr-lite");
    },
  },
  methods: {
    editClass() {
      this.$emit("editClass", { ...this.classData, time: this.classTime });
    },
    deleteClass() {
      this.$emit("deleteClass", { ...this.classData, time: this.classTime });
    },
    changePosition(up = true) {
      const weekDay = this.day;
      let position = this.classIndex ?? 0;
      if (up) position = position - 1;
      else position = position + 1;
      if (position <= 0) this.position = 0;

      const _class: IClassData = this.classesPerDay[
        this.classIndex
      ] as IClassData;

      let data = [
        ...(this.classesPerDay.filter(
          (_, ind) => ind != this.classIndex
        ) as Array<IClassData>),
      ].sort((a, b) => a.position - b.position);

      data.splice(position, 0, _class);
      data = data.map((ele, position) => ({ ...ele, position, weekDay }));

      this.$store.dispatch("classes/doChangePosition", data).catch((error) => {
        alert(error);
      });
    },
    // : "done" | "current" | "future" | "locked" | "inactive"
    getClassStatus() {
      const now = dayjs();
      const classTime = dayjs(this.classDate as Date);
      if (this.classData.active === false) {
        return "inactive";
      }
      if (this.classData.isLockedForUser) {
        return "locked";
      }
      if (
        now.isBetween(
          classTime.subtract(15, "minute"),
          classTime.add(1, "hour")
        )
      ) {
        if (
          this.data.requiredRank != undefined &&
          this.data.requiredRank != "INITIAL"
        ) {
          return "locked";
        } else {
          return "current";
        }
      } else if (classTime.isAfter(now)) {
        return "future";
      } else if (classTime.isBefore(now)) {
        return "done";
      }
      return "done";
    },
    setClassStatus() {
      const status = this.getClassStatus();
      if (this.classStatus != status) this.classStatus = status;
    },
    changeStatus() {
      const oldClassData: ClassData = {
        ...this.classData,
        time: this.classTime,
      } as ClassData;
      const newClassData: ClassData = {
        ...oldClassData,
        active: !oldClassData.active,
      } as ClassData;

      this.$store
        .dispatch("classes/doUpdateClass", { newClassData, oldClassData })
        .catch((error) => {
          alert(error);
        });
    },
    confirmRemove() {
      this.$emit("deleteClass", { ...this.classData, time: this.classTime });
    },
    getImage() {
      if (
        (this.data.imageUrl && this.data.imageUrl.startsWith("data:image")) ||
        this.data.imageUrl.search("http") >= 0
      ) {
        return this.data.imageUrl;
      } else {
        const defaultImage = "default-card.png";
        try {
          return require(`../../assets/img/classes/${this.data.imageUrl}`);
        } catch {
          return require(`../../assets/img/classes/${defaultImage}`);
        }
      }
    },
    openClass(url: string) {
      const _url = url + "?s=" + new Date().getTime().toString().substring(4);
      const newWindow = window.open(_url);
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed == "undefined"
      ) {
        alert(
          "Favor de permitir las Ventanas Emergentes (Popups) en su navegador"
        );
      }
    },
  },
  mutations: {
    setStatus(state, status) {
      state.classData.active = status;
    },
  },
  watch: {
    data: function () {
      this.setClassStatus();
    },
    classTime: function () {
      this.setClassStatus();
    },
    active: function () {
      this.setClassStatus();
    },
  },
  mounted() {
    this.setClassStatus();
    this.active = this.isActive;
  },
  beforeMount() {
    this.link = this.classData.link;
    this.data = this.classData as IClassData;
    this.position =
      this.classData.position == 0 && this.classIndex != 0
        ? this.classIndex
        : this.classData.position ?? 0;
  },
});
