/* eslint-disable @typescript-eslint/no-unused-vars */
import * as fb from "../../firebase";
import {
  QuoteSubscriptionRequest,
  SubscriptionInfo,
  SubscriptionPackage,
  SubscriptionPromotion,
  SubscriptionUpgrade,
} from "functions/src/Subscriptions/SubscriptionsInterfaces";
import { UserSubscription } from "functions/src/Users/UsersInterfaces";
import {
  SET_PROMOTIONS_CONFIG,
  SET_SUBS_CONFIG,
  SET_USER_ACTIVE,
  SET_USER_CURRENT_PACKAGE,
  SET_USER_SUBS,
  SubscriptionsState,
  UNSET_PROMOTIONS_CONFIG,
  UNSET_SUBS_CONFIG,
  UNSET_USER_SUBS,
  SET_IS_USER_DISTRIBUTOR,
  SET_UPGRADES_CONFIG,
  UNSET_UPGRADES_CONFIG,
} from "./SubscriptionsConsts";
import { ActionContext } from "vuex";
import dayjs from "dayjs";
import { _determineUserPackageAndStatus } from "./tsFunctions";

const initialState: SubscriptionsState = {
  SubscriptionsConfiguration: [],
  UserSubscriptions: [],
  SubscriptionsPromotions: new Array<[string, SubscriptionPromotion]>(),
  SubscriptionsUpgrades: new Array<[string, SubscriptionUpgrade]>(),
  UserPackage: "none",
  isUserActive: false,
  isUserDistributor: false,
};

const state = { ...initialState };

const snapshotListeners = {
  userSubscriptions: () => {
    return;
  },
  subscriptionConf: () => {
    return;
  },
  promotionsConf: () => {
    return;
  },
  upgradesConf: () => {
    return;
  },
};
const mutations = {
  SET_SUBS_CONFIG(state: SubscriptionsState, subscription: SubscriptionInfo) {
    const index = state.SubscriptionsConfiguration.findIndex((item: any) => {
      return item[0] == subscription.subscriptionId;
    });
    if (index >= 0) {
      state.SubscriptionsConfiguration[index] = [
        subscription.subscriptionId,
        subscription,
      ];
    } else {
      state.SubscriptionsConfiguration.push([
        subscription.subscriptionId,
        subscription,
      ]);
    }
  },
  SET_USER_SUBS(state: SubscriptionsState, subscription: UserSubscription) {
    const index = state.UserSubscriptions.findIndex((item: any) => {
      return item[0] == subscription.subscriptionId;
    });
    if (index >= 0) {
      state.UserSubscriptions[index] = [
        subscription.subscriptionId,
        subscription,
      ];
    } else {
      state.UserSubscriptions.push([subscription.subscriptionId, subscription]);
    }
  },
  SET_USER_CURRENT_PACKAGE(
    state: SubscriptionsState,
    userCurrentPackage: SubscriptionPackage
  ) {
    state.UserPackage = userCurrentPackage;
  },
  SET_USER_ACTIVE(state: SubscriptionsState, isActive: boolean) {
    state.isUserActive = isActive;
  },
  SET_IS_USER_DISTRIBUTOR(state: SubscriptionsState, isDistributor: boolean) {
    state.isUserDistributor = isDistributor;
  },
  UNSET_USER_SUBS(state: SubscriptionsState, subscriptionId: string) {
    state.UserSubscriptions = state.UserSubscriptions.filter((item) => {
      return item[0] != subscriptionId;
    });
  },
  SET_PROMOTIONS_CONFIG(
    state: SubscriptionsState,
    promotion: SubscriptionPromotion
  ) {
    const index = state.SubscriptionsPromotions.findIndex((item: any) => {
      return item[0] == promotion._id;
    });
    if (index >= 0) {
      state.SubscriptionsPromotions[index] = [promotion._id, promotion];
    } else {
      state.SubscriptionsPromotions.push([promotion._id, promotion]);
    }
  },
  SET_UPGRADES_CONFIG(state: SubscriptionsState, upgrade: SubscriptionUpgrade) {
    const index = state.SubscriptionsUpgrades.findIndex((item: any) => {
      return item[0] == upgrade._id;
    });
    if (index >= 0) {
      state.SubscriptionsUpgrades[index] = [upgrade._id, upgrade];
    } else {
      state.SubscriptionsUpgrades.push([upgrade._id, upgrade]);
    }
  },
  CLEAR_USER_SUBS(state: SubscriptionsState) {
    state.UserSubscriptions = [];
    state.UserPackage = "none";
    state.isUserActive = false;
    state.isUserDistributor = false;
  },
  UNSET_SUBS_CONFIG(state: SubscriptionsState, subscriptionId: string) {
    state.SubscriptionsConfiguration = state.SubscriptionsConfiguration.filter(
      (item) => {
        return item[0] != subscriptionId;
      }
    );
  },
  UNSET_PROMOTIONS_CONFIG(state: SubscriptionsState, promotionId: string) {
    state.SubscriptionsPromotions = state.SubscriptionsPromotions.filter(
      (item) => {
        return item[0] != promotionId;
      }
    );
  },
  UNSET_UPGRADES_CONFIG(state: SubscriptionsState, upgradeId: string) {
    state.SubscriptionsUpgrades = state.SubscriptionsUpgrades.filter((item) => {
      return item[0] != upgradeId;
    });
  },
};

const actions = {
  async doGetUserSubscriptions(
    context: ActionContext<SubscriptionsState, any>
  ) {
    const username = context.rootState.auth.userProfileData._usernamePK;
    const _userSubscriptions = fb.fbfs.collection(
      "users/" + username + "/subscriptions/"
    );
    let { userCurrentPackage, isActive, isDistributor } = {
      userCurrentPackage: "none",
      isActive: false,
      isDistributor: false,
    };
    context.commit(SET_USER_CURRENT_PACKAGE, userCurrentPackage);
    context.commit(SET_USER_ACTIVE, isActive);
    context.commit(SET_IS_USER_DISTRIBUTOR, isDistributor);
    //try {
    const listener = _userSubscriptions.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach(async (change) => {
        const doc = change.doc;
        if (change.type == "added" || change.type == "modified") {
          const startDate = doc.data().startDate.toDate();
          const endDate = doc.data().endDate.toDate();
          const lastPayDate = doc.data().lastPayDate.toDate();

          const subscription = {
            subscriptionId: doc.id,
            startDate: startDate,
            endDate: endDate,
            lastPayDate: lastPayDate,
            username: username,
          } as UserSubscription;
          if (
            subscription.endDate != undefined &&
            subscription.startDate != undefined
          ) {
            subscription.isActive = subscription.endDate > new Date();
          }
          const now = dayjs();
          const end = dayjs(subscription.endDate);
          subscription.daysLeft = Math.floor(end.diff(now, "day", true));

          context.commit(SET_USER_SUBS, subscription);
        } else if (change.type == "removed") {
          context.commit(UNSET_USER_SUBS, doc.id);
        }
        const userSubscriptions = context.state.UserSubscriptions.map(
          (item) => item[1]
        );
        const result = await _determineUserPackageAndStatus(username);
        userCurrentPackage = result.currentPackage;
        isActive = result.isPackageActive;
        isDistributor = result.isLicenseActive;
        context.commit(SET_USER_CURRENT_PACKAGE, userCurrentPackage);
        context.commit(SET_USER_ACTIVE, isActive);
        context.commit(SET_IS_USER_DISTRIBUTOR, isDistributor);
      });
    });
    snapshotListeners.userSubscriptions = listener;
    //} catch (error) {
    //  return await Promise.reject(error);
    //}
  },
  async doGetSubscriptionsConfig(
    context: ActionContext<SubscriptionsState, any>
  ) {
    const _subscriptions = fb.fbfs.collection("subscriptions");
    const listener = _subscriptions.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;

        if (change.type == "added" || change.type == "modified") {
          const subscription = doc.data();
          subscription.subscriptionId = doc.id;
          context.commit(SET_SUBS_CONFIG, subscription);
        } else if (change.type == "removed") {
          context.commit(UNSET_SUBS_CONFIG, doc.id);
        }
      });
    });
    const _promotions = fb.fbfs
      .collectionGroup("promotions")
      .where("status", "==", "active")
      .where("_type", "==", "subscription");
    const promotionsListener = _promotions.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const promotion = doc.data() as SubscriptionPromotion;

        if (change.type == "added" || change.type == "modified") {
          context.commit(SET_PROMOTIONS_CONFIG, promotion);
        } else if (change.type == "removed") {
          context.commit(UNSET_PROMOTIONS_CONFIG, promotion._id);
        }
      });
    });
    const _upgrades = fb.fbfs
      .collectionGroup("subscription-upgrades")
      .where("status", "==", "active")
      .where("_type", "==", "upgrade");
    const upgradesListener = _upgrades.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const upgrade = doc.data() as SubscriptionUpgrade;

        if (change.type == "added" || change.type == "modified") {
          context.commit(SET_UPGRADES_CONFIG, upgrade);
        } else if (change.type == "removed") {
          context.commit(UNSET_UPGRADES_CONFIG, upgrade._id);
        }
      });
    });
    snapshotListeners.subscriptionConf = listener;
    snapshotListeners.promotionsConf = promotionsListener;
    snapshotListeners.upgradesConf = upgradesListener;
  },
  async quoteSubscription(
    context: ActionContext<SubscriptionsState, any>,
    request: QuoteSubscriptionRequest
  ) {
    const quoteSubs = fb.func.httpsCallable("Payments-QuoteSubscription");
    return quoteSubs(request)
      .then((r) => {
        if (r.data.OPCODE == "ERROR") {
          return r.data;
        } else {
          // remove 5 minutes from expiration time
          r.data.expirationTime = new Date(
            (r.data.expirationTime as any)._seconds * 1000
          );
          r.data.createdDate = new Date(
            (r.data.createdDate as any)._seconds * 1000
          );
          r.data.updatedDate = new Date(
            (r.data.updatedDate as any)._seconds * 1000
          );
          return r.data;
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        throw new Error(error);
      });
  },
  unsubscribeUserSubsListener() {
    snapshotListeners.userSubscriptions();
  },
  unsubscribeSubscriptionsConfListener() {
    snapshotListeners.subscriptionConf();
    snapshotListeners.promotionsConf();
    snapshotListeners.upgradesConf();
  },
};

const getters = {
  getUserSubscriptions(
    state: SubscriptionsState
  ): Array<[string, UserSubscription]> {
    return state.UserSubscriptions;
  },

  getSubscriptionsConfig(state: SubscriptionsState): Array<SubscriptionInfo> {
    return state.SubscriptionsConfiguration;
  },

  isVIP(state: SubscriptionsState, getters: any, rootState: any): boolean {
    // Determine if user is VIP by checking that the current package is gtr-vip
    return state.UserPackage === "gtr-vip";
  },
  getUserCurrentPackage(state: SubscriptionsState): SubscriptionPackage {
    return state.UserPackage;
  },
  isUserActive(state: SubscriptionsState): boolean {
    return state.isUserActive;
  },
  isUserDistributor(state: SubscriptionsState): boolean {
    return state.isUserDistributor;
  },
  getUserSubscription(state: SubscriptionsState, getters: any, rootState: any) {
    return (subscriptionId: string): UserSubscription => {
      const subs =
        (new Map(...[state.UserSubscriptions]).get(
          subscriptionId
        ) as UserSubscription) ||
        ({
          username: rootState.auth.userProfileData?._usernamePK,
          subscriptionId: subscriptionId,
          isFirstTime: true,
        } as UserSubscription);
      return subs;
    };
  },
  getSubscriptionConfig(state: SubscriptionsState) {
    return (subscriptionId: string): SubscriptionInfo | undefined => {
      return new Map(...[state.SubscriptionsConfiguration]).get(
        subscriptionId
      ) as SubscriptionInfo;
    };
  },
  durationUnitLocalized(state: SubscriptionsState) {
    return (duration: number, durationUnit: string): string => {
      let response = "";
      const lang = "es-MX";
      if (lang == "es-MX") {
        switch (durationUnit) {
          case "days":
          case "remainingDays":
            switch (duration as number) {
              case 1:
                response = "día";
                break;
              case 0:
              default:
                response = "días";
                break;
            }
            break;
          case "months":
            switch (duration as number) {
              case 1:
                response = "mes";
                break;
              case 0:
              default:
                response = "meses";
                break;
            }
            break;
          case "years":
            switch (duration as number) {
              case 1:
                response = "año";
                break;
              case 0:
              default:
                response = "años";
                break;
            }
            break;
        }
      }
      return response;
    };
  },
  getSubscriptionsPromotions(
    state: SubscriptionsState
  ): Array<SubscriptionPromotion> {
    return state.SubscriptionsPromotions.map((item) => {
      return item[1];
    });
  },
  getSubscriptionsUpgrades(
    state: SubscriptionsState
  ): Array<SubscriptionUpgrade> {
    return state.SubscriptionsUpgrades.map((item) => {
      return item[1];
    });
  },
  getSubscriptionsUpgrade(state: SubscriptionsState, getters: any) {
    return (upgradeId: string): SubscriptionUpgrade | undefined => {
      return new Map(...[state.SubscriptionsUpgrades]).get(
        upgradeId
      ) as SubscriptionUpgrade;
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
